@use 'const';
@use 'designSystem';
@use 'responsive';

$socialIconSize: 22px;
$socialIconMobileSize: 20px;

.socials {
  display: flex;
  grid-gap: const.$margin;

  a {
    display: flex;
    line-height: $socialIconSize;
    align-items: center;

    svg {
      width: auto;
      height: $socialIconSize;
    }

    @include responsive.mobile {
      line-height: $socialIconMobileSize;

      svg {
        height: $socialIconMobileSize;
      }
    }
  }
}

.reviews {
  display: inline-flex;
  flex-direction: row;
  grid-gap: 15px;

  @include responsive.smallPhone {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    margin-top: 8px;
  }

  p {
    @include designSystem.styleFooterMenu;

    @include responsive.smallPhone {
      font-size: 18px;
    }
  }

  .stars {
    display: flex;
    flex-direction: row;
    grid-gap: 8px;

    svg {
      path {
        fill: designSystem.$colorPrimaryBrand;
        stroke: none;
      }
    }
  }
}
