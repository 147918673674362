@use 'const';
@use 'responsive';
@use 'theme';
@use 'mixins';
@use 'designSystem';

.header {
  border-bottom: 1px solid;
  display: grid;
  position: sticky;
  top: 0;
  width: 100%;
  height: const.$headerHeight;
  margin: auto;
  z-index: 4;
  align-items: center;
  transition: all 0.2s linear, opacity 0.5 linear;

  @include theme.apply(background-color, neutral-white);
  @include theme.apply(border-color, foreground-subtle);

  @include responsive.mobile {
    height: const.$headerHeightMobile;
  }

  &.fullWidth {
    .inner {
      max-width: 100%;
    }
  }

  &.hideHeader {
    top: -(const.$headerHeight);
  }

  &.sticky {
    position: sticky;
    top: 0;
  }

  &.homepageStyle {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    &:hover,
    &[data-active='true'] {
      background-color: var(--colour-neutral-white) !important; //stylelint-disable-line
    }

    &:not(:hover):not([data-active='true']):not(.heroScrolled) {
      a,
      button {
        &[class*='logo'] {
          @include mixins.svgColor(neutral-white);

          picture {
            &:nth-child(1) {
              opacity: 0;
            }

            &:nth-child(2) {
              opacity: 1;
            }
          }
        }
      }
    }

    &:not(:hover):not([data-active='true']) {
      background-color: transparent;
      border-color: transparent;

      a,
      button {
        @include theme.apply(color, neutral-white);

        &:not([class*='logo']) {
          @include mixins.svgColor(neutral-white);

          &:hover {
            @include theme.apply(color, action-default);

            @include responsive.gteMobile {
              @include mixins.svgColor(action-default);
            }
          }
        }
      }

      &.heroScrolled {
        @include theme.apply(background-color, neutral-white);
        @include theme.apply(border-color, foreground-subtle);

        position: fixed;

        a,
        button {
          @include theme.apply(color, foreground-default);

          &:not([class*='logo']) {
            @include mixins.svgColor(foreground-default);
          }
        }
      }
    }
  }

  a,
  button {
    display: grid;
    position: relative;
    padding: const.$headerMenuPadding;
    border-radius: const.$headerMenuRadius;
    background-color: transparent;
    text-decoration: none;
    align-items: center;
    grid-auto-flow: column;
    transition: background-color 0.5s;
    border: 0;

    @include theme.apply(color, foreground-default);
    @include designSystem.textStyleHeadingSmall16;

    &:not([class*='logo']) {
      @include mixins.svgColor(foreground-default);

      &:hover {
        cursor: pointer;

        @include theme.apply(color, action-default);

        @include responsive.gteMobile {
          @include mixins.svgColor(action-default);
        }
      }
    }

    &[data-active='true'] {
      @include theme.apply(color, foreground-default);
      @include mixins.svgColor(foreground-default);

      @include responsive.gteMobile {
        @include theme.apply(background-color, action-lightest);
        @include theme.apply(color, action-default);
        @include mixins.svgColor(action-default);
      }
    }
  }

  > .inner {
    display: flex;
    width: 100%;
    max-width: const.$contentMaxWidth;
    margin: auto;
    padding: 0 16px;
    align-items: center;
    justify-content: space-between;

    @include responsive.mobile {
      padding: 16px;
    }

    .left {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      grid-gap: const.$margin * 2;

      @include responsive.ltDesktop {
        grid-gap: const.$margin;
      }

      @include responsive.tinyPhone {
        grid-gap: const.$marginMobile;
      }
    }

    .right {
      display: flex;
      width: max-content;
      gap: 16px;
      z-index: 5;

      &.isFinanceSite {
        > a:nth-child(2) {
          @include responsive.ltDesktop {
            display: none;
          }
        }
      }

      @include responsive.mobile {
        gap: 0;

        button {
          &[data-active='true'] {
            @include theme.apply(background-color, action-lightest);
          }
        }
      }

      .mobileMenu {
        padding: 10px 16px;

        svg {
          width: 24px;
          height: 24px;
        }

        @include responsive.gtMobile {
          display: none;
        }

        &.mobileMenuActive {
          @include theme.apply(color, action-default);
        }
      }

      a,
      button {
        grid-gap: 8px;
        height: inherit;

        @include responsive.mobile {
          span:nth-child(2) {
            display: none;
          }
        }
      }

      a {
        width: max-content;
      }
    }
  }

  &.favourites {
    transition: 250ms ease-in all;

    &.hideHeader {
      top: -(const.$headerHeight) - 200px;
    }

    > .inner {
      max-width: const.$favouritesMaxWidth;
      padding: 0 16px;

      @include responsive.xxlDesktop {
        padding: 0;
      }
    }
  }
}
