@use 'const';
@use 'responsive';
@use 'theme';
@use 'designSystem';

$socialIconSize: 24px;
$leagueIconHeight: 26px;

footer.footer {
  position: relative;
  padding: 80px 0;
  width: 100%;

  @include theme.apply(color, neutral-white);
  @include theme.apply(background-color, foreground-default);

  @include responsive.ltLargeDesktop {
    padding: const.$margin * 2 0;
  }

  &.withStickyBottom {
    @include responsive.ltDesktop {
      padding: 48px 0 108px;
    }
  }

  .footerTop,
  .footerMiddle,
  .footerBottom {
    > div {
      position: relative;
      width: 100%;
      max-width: const.$contentMaxWidth;
      margin: 0 auto;
      padding: 0 const.$margin;
    }

    &.footerTop {
      @include responsive.mobile {
        padding: 0 0 8px;
      }

      > div > div {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &:not(.footerTop) {
      > div > div {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;

        @include responsive.mobile {
          flex-direction: column;
          gap: 24px;
        }
      }

      &.footerBottom {
        > div > div {
          @include responsive.mobile {
            flex-direction: column-reverse;
          }
        }
      }
    }
  }

  > .footerTop {
    display: flex;
    width: 100%;
  }

  > .footerMiddle {
    padding: const.$margin * 1.5 0;

    @include responsive.mobile {
      padding: 20px 0;
    }
  }

  > .footerBottom {
    display: flex;
    padding: 20px 0 0;
    width: 100%;

    > div > div {
      > span {
        @include designSystem.textStyleBodySmall16;

        display: flex;
        text-decoration: none;
        align-items: center;
        line-height: $leagueIconHeight;

        @include theme.apply(color, neutral-grey-75);

        a {
          @include theme.apply(color, neutral-grey-75);

          text-decoration: underline;
        }
      }

      > a {
        @include designSystem.textStyleHeadingSmall12;
        @include theme.apply(color, neutral-grey-75);

        font-weight: normal;
        line-height: $leagueIconHeight;
        text-decoration: none;
        display: flex;
        align-items: center;

        svg {
          width: auto;
          height: $leagueIconHeight;
          margin-left: 10px;
        }
      }
    }
  }
}

.reviews {
  display: inline-flex;
  flex-direction: row;
  grid-gap: 15px;

  @include responsive.mobile {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
  }

  p {
    @include designSystem.styleFooterMenu;

    @include responsive.smallPhone {
      font-size: 18px;
    }
  }

  .stars {
    display: flex;
    flex-direction: row;
    grid-gap: 8px;

    svg {
      path {
        @include theme.apply(fill, brand-default);

        stroke: none;
      }
    }

    &.usedCarPlatform {
      svg {
        path {
          @include theme.apply(fill, foreground-on-foreground);

          stroke: none;
        }
      }
    }
  }
}

[data-site='carplace'] {
  .footer {
    background: linear-gradient(90deg, #0e4a5d 0.04%, #005e73 99.96%);
  }
}
