@use 'const';
@use 'responsive';

a.logo {
  min-width: 110px;
  padding: 0 !important; //stylelint-disable-line

  img,
  svg {
    display: flex;
    width: auto;
    max-width: 100%;
    max-height: const.$headerHeight - 48px; // padding top and bottom 24px
    object-fit: contain;

    @include responsive.mobile {
      height: const.$headerHeightMobile - 32px; // padding top and bottom 16px
    }
  }

  > picture {
    &:nth-child(1) {
      opacity: 1;
    }

    &:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      opacity: 0;
    }
  }
}

[data-site='waCarBuyingCentre'] {
  a.logo {
    max-width: 160px;
  }
}
