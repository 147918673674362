@use 'const';
@use 'responsive';
@use 'theme';
@use 'designSystem';

$locationIconSize: 27px;

nav.navFooter {
  display: flex;
  gap: 80px;
  width: 100%;

  @include responsive.ltXlDesktop {
    gap: const.$margin * 2;
  }

  @include responsive.ltLargeDesktop {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: const.$margin * 1.5;
    width: 100%;
  }

  @include responsive.ltDesktop {
    grid-template-columns: repeat(3, max-content);
    grid-gap: const.$margin;
  }

  @include responsive.mobile {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  > div,
  ul {
    display: inline-flex;
    margin: 0;
    padding: 0;
    list-style: none;
    align-self: flex-start;
    flex-direction: column;
  }

  > div {
    gap: const.$margin * 1.5;

    @include responsive.ltLargeDesktop {
      &:last-child {
        grid-column: 1 / span 3;
      }
    }

    @include responsive.mobile {
      display: flex;
      gap: const.$margin;
    }
  }

  ul {
    &:last-child {
      padding: 0;
    }

    li {
      margin-bottom: 12px;

      @include responsive.smallPhone {
        &.parent {
          margin-bottom: 12px;

          > span {
            @include theme.apply(color, neutral-white);
          }

          a {
            color: designSystem.$colorSecondaryDividerLines;
          }
        }
      }

      &.parent:not(:first-child) {
        margin-top: 36px;
      }

      &:first-child:not(.parent) {
        & a,
        & ~ li:not(.parent) a {
          font-weight: bold;
        }
      }

      &.locations {
        display: flex;
        gap: 10px;
        margin-top: 12px;

        @include responsive.smallPhone {
          margin-top: 24px;
        }

        a {
          @include designSystem.styleFooterParentMenu;

          display: grid;
          grid-template-columns: 40px 1fr;
          align-items: flex-start;
          line-height: $locationIconSize;

          svg {
            @include theme.apply(color, brand-default);

            margin-right: 12px;
          }
        }

        &.nonMyMoto {
          svg {
            @include theme.apply(color, foreground-on-foreground);
          }
        }
      }

      &.locationText {
        a {
          display: flex;
          max-width: 225px;
        }
      }

      .contactText {
        display: flex;
        flex-direction: column;
        gap: 12px;

        p {
          @include designSystem.styleFooterMenu;
          @include theme.apply(color, neutral-white);
          @include theme.apply(font-weight, body-weight);

          padding: 0;
          border: none;
          background: none;
          text-decoration: none;

          &:hover {
            @include theme.apply(color, foreground-lightest);
          }
        }
      }

      span {
        @include designSystem.styleFooterMenu;
        @include theme.apply(color, neutral-white);

        text-decoration: none;

        strong {
          @include designSystem.styleFooterParentMenu;
          @include theme.apply(font-weight, heading-weight);
        }
      }

      a,
      button {
        @include designSystem.styleFooterMenu;
        @include theme.apply(color, neutral-white);
        @include theme.apply(font-weight, body-weight);

        padding: 0;
        border: none;
        background: none;
        text-decoration: none;

        &:hover {
          @include theme.apply(color, foreground-lightest);
        }
      }
    }
  }
}
