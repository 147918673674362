@use 'const';
@use 'responsive';
@use 'designSystem';

.header {
  display: grid;
  position: sticky;
  top: 0;
  width: 100%;
  height: const.$headerHeight;
  background-color: designSystem.$colorTertiaryWhite;
  z-index: 3;
  align-items: center;

  > .inner {
    width: 100%;
    max-width: const.$contentMaxWidth;
    margin: auto;

    @include responsive.mobile {
      padding: const.$marginMobile;
    }
  }
}
