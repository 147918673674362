@use 'const';
@use 'responsive';
@use 'theme';
@use 'mixins';
@use 'designSystem';

$menuShadow: 0 0 1px rgb(48 49 51 / 0.05), 0 8px 32px rgb(48 49 51 / 0.25);

.navigation {
  display: flex;
  justify-content: left;
  gap: const.$margin * 1.5;
  flex-direction: row;
  white-space: nowrap;

  @include responsive.mobile {
    position: fixed;
    top: const.$headerHeightMobile;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 5;
    overflow-x: auto;
    animation: slideIn 125ms ease-in-out;

    @include theme.apply(background-color, neutral-white);

    &[data-active='false'] {
      display: none;
      animation: slideIn 600ms ease-in-out;
    }
  }

  .inner {
    display: flex;
    padding: 20px 0;
    align-items: center;
    flex-flow: row nowrap;
    gap: 16px;

    // only show 1 menu item on the resolution
    @include responsive.ltLargeDesktop {
      @include responsive.desktop {
        > a:not(:first-child) {
          display: none;
        }
      }
    }

    @include responsive.ltDesktop {
      gap: 16px;
    }

    @include responsive.mobile {
      display: grid;
      align-items: flex-end;
      padding: 16px 24px 40px;
      grid-gap: 0;
      grid-auto-flow: row;
      grid-template-columns: 1fr;
      width: 100%;
      height: max-content;

      @include theme.apply(border-color, foreground-subtle);

      a,
      p,
      button {
        border-bottom: 1px solid;
        border-radius: 0;
        padding: 20px 0;

        @include theme.apply(border-color, foreground-disabled);
        @include designSystem.textStyleHeadingLarge30;
      }

      button {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .contactMenu {
        padding: 20px 0;
        gap: 16px 0;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid;

        @include theme.apply(border-color, foreground-disabled);
      }

      .phone,
      .message,
      .contact {
        justify-content: flex-start;
        text-align: left;
        border: none;
        padding: 0;
        display: flex;

        svg {
          width: 24px;
          margin: 0 12px 0 0;
        }
      }

      .phone,
      .message {
        @include designSystem.textStyleBodyLarge24;
      }
    }

    .enquireMobile {
      display: none;
      margin-top: 24px;

      @include responsive.mobile {
        display: inherit;
      }

      a {
        @include designSystem.textStyleHeadingMedium24;

        padding-left: 30px;
        padding-right: 30px;
        height: const.$buttonheight;
        border: none;
        border-radius: const.$buttonRadius;
      }
    }

    .contactMobile {
      display: none;
      margin-bottom: 0;

      @include responsive.mobile {
        display: inherit;
      }

      a {
        border-bottom: none;

        &.contactItem {
          @include designSystem.textStyleBodyLarge24;

          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          gap: 12px;
          padding: 0;
        }
      }
    }

    .dd {
      &.isOpen {
        z-index: 1;

        @include responsive.mobile {
          border-bottom: 1px solid;

          @include theme.apply(border-color, foreground-disabled);
        }
      }

      .ddButton {
        &.ddButtonCars {
          &.isOpen {
            @include responsive.gtMobile {
              &::after {
                @include theme.apply(background-color, action-lightest);

                content: '';
                display: block;
                position: absolute;
                left: 12px;
                right: 12px;
                bottom: -26px;
                height: 6px;
              }
            }
          }
        }

        &.isOpen {
          @include theme.apply(color, foreground-default);

          border: none;
          z-index: 2;

          svg {
            transform: rotateX(-180deg);
          }
        }

        svg {
          margin-left: 8px;
        }
      }

      @include responsive.mobile {
        display: block;
        text-align: left;
      }

      .ddList {
        @include theme.apply(background-color, neutral-white);

        box-shadow: $menuShadow;
        position: absolute;
        left: auto;
        z-index: 3;
        border-radius: const.$margin;
        padding-top: const.$margin * 0.5;
        padding-bottom: const.$margin * 0.5;
        min-width: 170px;

        @include responsive.mobile {
          position: relative;
          box-shadow: none;
          padding-top: 0;

          > a {
            @include designSystem.textStyleBodyLarge24;

            border: none;
            padding-top: 12px;
            padding-bottom: 12px;
          }
        }
      }

      .carsList {
        @include theme.apply(background-color, neutral-white);

        z-index: 3;
        position: absolute;
        left: 0;
        right: 0;
        top: const.$headerHeight;
        display: none;

        @include responsive.mobile {
          position: relative;
          left: initial;
          right: initial;
          top: initial;
        }

        &::before {
          box-sizing: border-box;

          @include theme.apply(background-color, neutral-white);

          content: '';
          position: absolute;
          inset: 0;
          z-index: -1;

          @include responsive.mobile {
            content: none;
          }
        }

        &.hideDivider {
          .divider {
            display: none;
          }
        }

        &.isOpen {
          box-shadow: $menuShadow;
          display: flex;

          @include responsive.mobile {
            box-shadow: none;
            border-bottom: 1px solid;
            padding-bottom: 24px;

            @include theme.apply(border-color, foreground-subtle);
          }
        }

        .animationHeight {
          width: 100%;
        }

        .carsListContainer {
          max-width: const.$contentMaxWidth;
          margin: 0 auto;
          width: 100%;
          padding: 56px 80px 80px;
          display: flex;
          flex-direction: revert;
          gap: 80px;

          @include responsive.ltXlDesktop {
            padding: 56px 24px 80px;
            gap: 60px;
          }

          @include responsive.ltLargeDesktop {
            padding: 48px 24px 60px;
            gap: 36px;
          }

          @include responsive.ltMedium {
            padding: 48px 24px 36px;
            gap: 24px;
          }

          @include responsive.mobile {
            display: flex;
            flex-direction: column;
            gap: 0;
            padding: 0;
            height: unset;
            overflow: hidden;
          }

          &.isFinanceSite {
            .carsItems {
              @include responsive.mobile {
                display: none;
              }
            }

            .carsTypes {
              @include responsive.mobile {
                grid-template-columns: 1fr;
              }
            }
          }
        }

        p {
          @include theme.apply(color, foreground-muted);
          @include designSystem.textStyleBodySmall18;

          @include responsive.ltLargeDesktop {
            @include designSystem.textStyleBodySmall16;
          }

          @include responsive.mobile {
            @include designSystem.textStyleBodySmall18;
          }
        }

        .carsItems {
          display: flex;
          flex-direction: column;
          gap: 32px;

          @include responsive.ltMedium {
            gap: 24px;
          }

          @include responsive.mobile {
            border-bottom: 1px solid;
            gap: 32px;
            padding-top: 16px;
            padding-bottom: 24px;

            @include theme.apply(border-color, foreground-subtle);
          }

          p {
            @include responsive.mobile {
              display: none;
            }
          }

          a.carsItem {
            @include theme.apply(color, foreground-default);
            @include designSystem.textStyleHeadingSmall16;

            padding: 0;

            @include responsive.ltLargeDesktop {
              @include designSystem.textStyleHeadingSmall18;
            }

            @include responsive.ltMedium {
              @include designSystem.textStyleHeadingSmall16;
            }

            @include responsive.mobile {
              border: none;
            }

            &:hover {
              @include theme.apply(color, action-default);
            }
          }

          .shopAllCarListItem {
            a:hover {
              color: white;
            }

            a,
            button {
              @include designSystem.textStyleHeadingSmall18;
              /* stylelint-disable-next-line declaration-no-important */
              font-size: 18px !important;
            }

            @include responsive.mobile {
              a,
              button {
                @include theme.apply(background-color, foreground-default);
                @include theme.apply(border-color, foreground-default);

                border: none;
                width: 184px;
                height: 57px;
                padding: 17px 33px 18px;
                border-radius: 12px;
              }
            }
          }
        }

        .divider {
          @include theme.apply(background-color, foreground-disabled);

          width: 1px;

          @include responsive.mobile {
            display: none;
          }
        }

        .carsTypes {
          display: grid;
          grid-template-columns: repeat(3, max-content);
          grid-gap: 30px 60px;
          align-self: flex-start;

          @include designSystem.textStyleHeadingSmall18;

          @include responsive.ltXlDesktop {
            grid-gap: 24px 60px;
          }

          @include responsive.ltLargeDesktop {
            grid-gap: 24px 12px;
          }

          @include responsive.ltMedium {
            grid-gap: 24px 0;
          }

          @include responsive.mobile {
            grid-template-columns: repeat(2, 1fr);
          }

          @include responsive.smallPhone {
            grid-template-columns: 1fr;
            width: 100%;
          }

          p {
            grid-column: 1 / -1;

            @include responsive.mobile {
              border: none;
              padding-bottom: 0;
            }
          }

          .type {
            @include theme.apply(background-color, neutral-white);
            @include theme.apply(color, foreground-default);
            @include designSystem.textStyleHeadingSmall18;

            border: none;
            display: grid;
            grid-template-columns: 96px auto;
            gap: 16px;
            padding: 16px;
            border-radius: 6px;

            &:hover {
              @include theme.apply(background-color, foreground-lightest);
            }

            @include responsive.ltLargeDesktop {
              @include designSystem.textStyleHeadingSmall16;

              grid-template-columns: 80px auto;
            }

            @include responsive.ltMedium {
              @include designSystem.textStyleHeadingSmall14;

              grid-template-columns: 60px auto;
            }

            @include responsive.mobile {
              @include designSystem.textStyleHeadingSmall18;

              grid-template-columns: 96px auto;
              padding: 8px 0;
            }

            &.labelOnly {
              grid-template-columns: 1fr;
            }

            &.iconItem {
              grid-template-columns: 1fr;
              justify-self: flex-start;

              span {
                display: flex;
                justify-content: center;
              }

              @include responsive.mobile {
                @include designSystem.textStyleHeadingSmall18;

                grid-template-columns: 96px auto;
                gap: 16px;
                padding: 8px 0;
              }
            }

            > span {
              display: flex;

              svg,
              img {
                max-width: 96px;
                height: auto;

                @include responsive.ltLargeDesktop {
                  max-width: 80px;
                }

                @include responsive.ltMedium {
                  max-width: 60px;
                }

                @include responsive.mobile {
                  max-width: 96px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.backdrop {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  animation: fadeIn 0.4s;

  &.backdropCars {
    background-color: transparent;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.3;
  }
}

@keyframes slideIn {
  from {
    right: -100%;
  }

  to {
    right: 0;
  }
}
