@use 'theme';
@use 'responsive';
@use 'designSystem';
@use 'mixins';

a.favourites {
  @include responsive.mobile {
    display: flex !important; // stylelint-disable-line
    justify-content: center !important; // stylelint-disable-line
    gap: 8px !important; // stylelint-disable-line
    min-width: 56px !important; // stylelint-disable-line
    padding: 12px 0 !important; // stylelint-disable-line
  }

  &.brand {
    &:not([class*='logo']) {
      &:hover {
        cursor: pointer;

        @include theme.apply(color, brand-default);

        @include responsive.gteMobile {
          @include mixins.svgColor(brand-default);
        }
      }
    }

    &[data-active='true'] {
      @include theme.apply(color, foreground-default);
      @include mixins.svgColor(foreground-default);

      @include responsive.gteMobile {
        @include theme.apply(background-color, foreground-lightest);
        @include theme.apply(color, brand-default);
        @include mixins.svgColor(brand-default);
      }
    }
  }

  span {
    display: flex;
    position: relative;
    align-items: center;

    @include mixins.svgStrokeColor(foreground-default);
  }

  .count {
    @include designSystem.textStyleHeadingSmall16;
    @include theme.apply(color, neutral-white);
    @include theme.apply(background-color, brand-default);

    text-align: center;
    border-radius: 6px;
    padding: 2px 6px;
  }
}

[data-site='carplace'] { 
  .count {
    background: var(--colour-brand-default-or-gradient);
  }
}