@use 'const';
@use 'responsive';
@use 'theme';
@use 'mixins';
@use 'designSystem';

.button.button:not([class*='logo']) {
  &:hover {
    @include theme.apply(background-color, action-darken);
    @include theme.apply(border-color, action-darken);
    @include theme.apply(color, action-on-action);
    @include mixins.svgColor(action-on-action);
  }

  &:active {
    @include theme.apply(background-color, action-darkest);
    @include theme.apply(border-color, action-darkest);
    @include theme.apply(color, action-on-action);
    @include mixins.svgColor(action-on-action);
  }

  &:focus {
    @include theme.apply(background-color, action-darken);
    @include theme.apply(border-color, action-lighten);
    @include theme.apply(color, action-on-action);
    @include mixins.svgColor(action-on-action);
  }
}
