@use 'const';
@use 'responsive';

.logo {
  min-width: 110px;
  padding: 0 !important; //stylelint-disable-line
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;

  @include responsive.mobile {
    max-width: 220px;
  }

  img,
  svg {
    display: flex;
    width: auto;
    max-width: 100%;
    max-height: const.$headerHeight - 48px; // padding top and bottom 24px
    object-fit: contain;

    @include responsive.mobile {
      height: const.$headerHeightMobile - 32px; // padding top and bottom 16px
    }
  }
}

[data-site='waCarBuyingCentre'] {
  .logo {
    max-width: 160px;
  }
}
